import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import colors from '../../utils/colors'

const Button = styled.button`
  background-color: ${props => props.bgColor};
  border: none;
  color: ${props => props.color};
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 10px;
  
  :hover {
    opacity: 0.8;
  }
  
  a {
    color: ${props => props.color};
  }
`

Button.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string
}

Button.defaultProps = {
  bgColor: colors.brightGrey,
  color: colors.lightGrey
}

export default Button
