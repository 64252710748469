import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {breakpointMobile} from '../../utils/breakpoints'

const Column = styled.div`
  flex: ${props => props.flex};
  padding-right: ${props => props.paddingRight};
  
  ${breakpointMobile(`
    flex: none;
    padding-right: 0;
    width: 100%;
  `)}
`

Column.defaultProps = {
  flex: '1',
  paddingRight: '0'
}

Column.propTypes = {
  flex: PropTypes.string,
  paddingRight: PropTypes.string
}

export default Column
