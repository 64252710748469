const colors = {
  black: '#000000',
  darkGrey: '#3A3A3A',
  grey: '#444',
  lightGrey: '#5A5A5A',
  brightGrey: '#CCC',
  whiteGrey: '#EAEAEA',
  
  brownGrey: '#E9E8E3',
  
  gold: '#FFDE2F',
  
  green: '#5CB85C',

  red: '#F00',
  
  blue: "#0000FF",
  
  darkWhite: '#F5F5F5',
  creamWhite: '#FFFFF7',
  white: '#FFF'
}

export default colors
