export const BREAKPOINT_MOBILE = 426
export const BREAKPOINT_TABLET = 1025

export const breakpointMobile = (css) => `
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${css}
  }
`

export const breakpointTablet = (css) => `
  @media (max-width: ${BREAKPOINT_TABLET}px) {
    ${css}
  }
`
