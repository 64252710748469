import React, {PureComponent} from 'react'
import {Link} from '@reach/router'
import styled from 'styled-components'

import {breakpointMobile} from '../../utils/breakpoints'
import colors from '../../utils/colors'
import {getHTML, translate} from '../../utils/labels'
import Block from './Block'
import ButtonGold from './ButtonGold'
import Column from './Column'

const LS_COOKIECONSENT = 'cookieconsent'

const CookieConsentWrapper = styled.div`
  background-color: ${colors.brightGrey};
  border-top: 1px solid ${colors.lightGrey};
  bottom: 0;
  color: ${colors.grey};
  position: fixed;
  width: 100%;
  z-index: 10000;
`

const CookieConsentTextColumn = styled(Column)`
  display: flex;
  align-items: center;
`

const CookieConsentText = styled.p`
  margin: 0;
  
  a {
    color: ${colors.grey};
  }
`

const CookieConsentButton = styled(ButtonGold)`
  ${breakpointMobile(`
    float: right;
    margin: 10px 0;
  `)}
`

const getConsentFromLocalStorage = () => {
  const consentFromLocalStorage = window.localStorage.getItem(LS_COOKIECONSENT)

  if (!consentFromLocalStorage) {
    return false
  }

  return consentFromLocalStorage
}

export default class CookieConsent extends PureComponent {
  state = {
    closed: getConsentFromLocalStorage()
  }
  
  onClickCloseButton = () => {
    this.setState({
      closed: true
    }, () => window.localStorage.setItem(LS_COOKIECONSENT, true))
  }
  
  render() {
    if (this.state && this.state.closed) return null
    
    return (
      <CookieConsentWrapper>
        <Block display='flex' margin='10px'>
          <CookieConsentTextColumn>
            <CookieConsentText>{getHTML('cookieconsent.text')}</CookieConsentText>
          </CookieConsentTextColumn>
          <Column flex='inherit'>
            <CookieConsentButton onClick={this.onClickCloseButton}>{translate('cookieconsent.close')}</CookieConsentButton>          
          </Column>
        </Block>
      </CookieConsentWrapper>
    )
  }
}
