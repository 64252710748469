import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {breakpointMobile} from '../../utils/breakpoints'

const Block = styled.div`
  width: ${props => props.width};
  background-color: ${props => props.bgColor};
  display: ${props => props.display};
  flex-wrap: ${props => props.flexWrap};
  justify-content: ${props => props.justifyContent};
  margin: ${props => props.margin};
  text-align: ${props => props.align};
  
  ${breakpointMobile(`
    display: block;
  `)}
`

Block.propTypes = {
  align: PropTypes.string,
  width: PropTypes.string,
  bgColor: PropTypes.string,
  display: PropTypes.string,
  flexWrap: PropTypes.string,
  justifyContent: PropTypes.string,
  margin: PropTypes.string
}

Block.defaultProps = {
  align: 'left',
  bgColor: 'transparent',
  display: 'block',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  margin: '0'
}

export default Block
