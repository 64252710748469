let language = {
  currentLanguage: 'nl',
  isNL: true,
  isFR: false
}

if (typeof window !== 'undefined') {
  const {pathname} = window.location
  let isNL = pathname.indexOf('/nl') !== -1
  let isFR = pathname.indexOf('/fr') !== -1
  
  if (!isNL && !isFR) {
    const userLang = (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.userLanguage
      
    if (userLang.toLowerCase().indexOf('fr') !== -1) {
      isFR = true
      isNL = false      
    }
    else {
      isFR = false
      isNL = true
    }
  }
  
  const urlNL = isNL ? pathname : pathname.replace('/fr', '/nl')
  const urlFR = isNL ? pathname.replace('/nl', '/fr') : pathname
  
  const currentLanguage = isNL ? 'nl' : 'fr'
  
  Object.assign(language, {
    currentLanguage,
    isFR,
    isNL,
    urlFR,
    urlNL
  })
}

export default language
