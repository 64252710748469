import React from 'react'
import styled from 'styled-components'

import colors from '../../utils/colors'
import Button from './Button'

const ButtonGold = styled(Button)``

ButtonGold.defaultProps = {
  bgColor: colors.gold,
  color: colors.grey
}

export default ButtonGold
