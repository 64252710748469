import React, { Component } from 'react'
import { withSiteData, Root, Routes } from 'react-static'
import intl from 'react-intl-universal'

import language from './utils/language'
import CookieConsent from './components/ui/CookieConsent'

// locale data
const locales = {
  'fr': require('./labels/fr-BE.json'),
  'nl': require('./labels/nl-BE.json'),
}

class App extends Component {
  state = {initDone: false}
  
  componentDidMount() {
    this.loadLocales()
  }  
  
  loadLocales() {
    const {currentLanguage} = language

    // init method will load CLDR locale data according to currentLocale
    // react-intl-universal is singleton, so you should init it only once in your app
    intl.init({
      currentLocale: `${currentLanguage}`,
      locales
    })
    .then(() => {
      // After loading CLDR locale data, start to render
      this.setState({initDone: true})
    })

    intl.load({
      fr: require('./labels/fr-carmanager.json'),
      nl: require('./labels/nl-carmanager.json')
    })
    
    intl.load({
      fr: require('./labels/fr-testimonials.json'),
      nl: require('./labels/nl-testimonials.json')
    })
  }
  
  render() {
    return (
      this.state.initDone &&
      <Root>
        <CookieConsent />
        <Routes />
      </Root>
    )    
  }
}

export default withSiteData(App)
